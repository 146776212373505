import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import classNames from 'classnames';

import Layout from '../components/Layout';
import SectionFeature from '../components/sections/SectionFeature';
import SectionEarlyAccess from '../components/sections/SectionEarlyAccess';

import useResizeScreen from '../hooks/useResize';

import logoElastic from '../assets/images/icons/logo-full-svg/elastic.svg';
import logoOpenTelemetry from '../assets/images/icons/logo-full-svg/open-telemetry.svg';
import logoPrometheus from '../assets/images/icons/logo-full-svg/prometheus.svg';
import iconLowCode from '../assets/images/icons/general-svg/icon-low-code2.svg';

const CloudMapperPage = () => {
  const { isMobile } = useResizeScreen();
  return (
    <Layout title="Jetic - Cloud Data Mapper Solution">
      <div className="api-builder-page">
        <section className="first-section first-section--common">
          <div className="container pt-4">
            <div className="row">
              <div className="col-md-6">
                <div className="first-section__content">
                  <h1 className="header-700 mb-4">Cloud Mapper</h1>
                  <h2 className="body-300 gray-500 mb-32">
                    Visually map and transform anything into anything else by just using open-source
                  </h2>
                  <div className="btn-wrapper mb-3 pb-3">
                    <a
                      href="https://app.jetic.io/registration"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="c-btn c-btn--primary me-3"
                    >
                      Sign up
                    </a>
                    <a
                      href="https://docs.jetic.io/docs/Components/cloud_mapper"
                      className="c-btn c-btn--white"
                    >
                      Get started guide
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                {!isMobile ? (
                  <StaticImage
                    src="../assets/images/pages/cloud-mapper/Illustration-main.png"
                    className="first-section__image illustration w-100"
                    alt="Cloud Mapper"
                  />
                ) : (
                  <StaticImage
                    src="../assets/images/pages/cloud-mapper/Illustration-main--mobile.png"
                    className="first-section__image illustration w-100"
                    alt="Cloud Mapper"
                  />
                )}
              </div>
            </div>
          </div>
        </section>

        <section className="c-section--primary">
          <div className="container">
            <div className="row">
              <h3 className="header-600 mb-4">Complex mappings simplified.</h3>
              <div className="col-md-5">
                <ul className="list-disc body-300">
                  <li>Graphically create data mappings without any installation</li>
                  <li>Fully integrated into the Jetic Platform to solve your data challenge</li>
                  <li>Support for latest industry standards as XML, JSON, CSV, ...</li>
                </ul>
              </div>
              <div className="col-md-6 offset-md-1 mt-4 mt-md-0">
                <p className="body-300">
                  Cloud Data Mapper - based on the popular open-source framework Atlasmap - lets you
                  quickly transform and map a variety of hierarchical data formats. Thanks to a
                  seamless integration into our platform all mappings can easily be used within any
                  integration or simply deployed as standalone APIs.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="c-section--white">
          <div className="container">
            <SectionFeature
              sectionClassName="pb-80"
              contentLabelText="Visualize"
              contentTitleText="Import Your Data Schema"
              contentBodyTags={
                <>
                  <p className="body-300 gray-400 mb-4">
                    The Cloud Data Mapper can analyze and import a variety of large and complex data
                    formats such as XML, JSON, CSV, Java, and even DFDL. Simply import source and
                    target data format and start mapping right away.
                  </p>
                </>
              }
              contentImage={{
                tag: !isMobile ? (
                  <StaticImage
                    className="illustration"
                    src="../assets/images/pages/cloud-mapper/Illustration-design.png"
                    alt="Import Your Data Schema"
                  />
                ) : (
                  <StaticImage
                    className="illustration"
                    src="../assets/images/pages/cloud-mapper/Illustration-design--mobile.png"
                    alt="Import Your Data Schema"
                  />
                ),
              }}
            />

            <SectionFeature
              sectionClassName="py-80"
              contentLabelText="Map & Transform"
              contentTitleText="Create Complex Mappings"
              contentBodyTags={
                <>
                  <p className="body-300 gray-400 mb-4">
                    No matter if you want to handle large data sets with thousands of nodes or
                    complex hierarchies: Simply drag and drop until you are satisfied with the
                    mapping result and choose from a collection of functions to transform the data
                    into the destinations target format.
                  </p>
                </>
              }
              contentImage={{
                tag: !isMobile ? (
                  <StaticImage
                    className="illustration"
                    src="../assets/images/pages/cloud-mapper/Illustration-implement.png"
                    alt="Create Complex Mappings"
                  />
                ) : (
                  <StaticImage
                    className="illustration"
                    src="../assets/images/pages/cloud-mapper/Illustration-implement--mobile.png"
                    alt="Create Complex Mappings"
                  />
                ),
              }}
            />

            <SectionFeature
              sectionClassName="py-80"
              contentLabelText="Deploy & Expose"
              contentTitleText="Standalone or Fully Integrated"
              contentBodyTags={
                <>
                  <p className="body-300 gray-400 mb-4">
                    Each mapping can be build as a standalone mapping solution and read from and
                    output to using one of our hundreds of connectors such as API, File, Database,
                    and many more. It can also become part of a your complex integration and even
                    process data from multiple input sources.
                  </p>
                </>
              }
              contentImage={{
                tag: !isMobile ? (
                  <StaticImage
                    className="illustration"
                    src="../assets/images/pages/cloud-mapper/Illustration-expose-scale.png"
                    alt="Standalone or Fully Integrated"
                  />
                ) : (
                  <StaticImage
                    className="illustration"
                    src="../assets/images/pages/cloud-mapper/Illustration-expose-scale--mobile.png"
                    alt="Standalone or Fully Integrated"
                  />
                ),
              }}
            />
          </div>
        </section>

        <section className="c-section--gray py-80">
          <div className="container">
            <h3 className="header-600 text-center mb-0">
              Graphical, complex data mapping solution based on open-source technologies.
            </h3>
          </div>
        </section>

        <section className="py-80" style={{ boxShadow: 'inset 0px -1px 0px #E8E9EA' }}>
          <div className="container">
            <div className="row mb-80">
              <div className="col-lg-11 mx-auto">
                {!isMobile ? (
                  <StaticImage
                    className="illustration"
                    src="../assets/images/pages/cloud-mapper/Illustration-vb.png"
                    alt="image"
                  />
                ) : (
                  <StaticImage
                    className="illustration"
                    src="../assets/images/pages/cloud-mapper/Illustration-vb--mobile.png"
                    alt="image"
                  />
                )}
              </div>
            </div>
          </div>
        </section>

        <section className="py-80">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 mx-auto">
                <div className="row">
                  <div
                    className={classNames('col-md-6 col-lg-5 d-flex align-items-center', {
                      'flex-wrap': isMobile,
                      'justify-content-center': isMobile,
                    })}
                  >
                    {isMobile && (
                      <img
                        className="mb-4"
                        src={iconLowCode}
                        alt="Build trusted, reliable Data-as-a-Service Platforms."
                        height={48}
                        width={48}
                      />
                    )}
                    <h3
                      className={classNames('header-600', {
                        'text-center': isMobile,
                      })}
                    >
                      Build trusted, reliable Data-as-a-Service Platforms.
                    </h3>
                  </div>
                  {!isMobile && (
                    <div className="col-md-6 offset-lg-1">
                      <StaticImage
                        className="illustration"
                        src="../assets/images/pages/cloud-mapper/Illustration-daasp.png"
                        alt="Build trusted, reliable Data-as-a-Service Platforms."
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <SectionEarlyAccess />
      </div>
    </Layout>
  );
};

export default CloudMapperPage;
